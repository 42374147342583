// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.modal-details-row{
    margin-left: 0.5rem;
}

.header-schema{
    font-family: 'Poppins', sans-serif;
    color: #FFFFFF;
}

.main2Div{
    background-color: #030B15;
}

.table-schema{
    /* color: #FFFFFF !important;  */
    font-size: 1.1rem !important;
    font-weight: 3500;
    --bs-table-color: #FFFFFF !important;
    --bs-table-bg: #030B15 !important;
}

.table-border-bottom{
    border-bottom: 2px solid rgba(255, 252, 252, 0.096);
    font-family: 'Outfit', sans-serif !important;
    /* font-size: 1rem !important;
    font-weight: 400 !important;  */
}

.modal-header-schema{
    background: #0B1D33 !important;
}

.modal-schema{
    font-family: 'Outfit', sans-serif !important;
    font-size: 1.1rem !important;
    font-weight: 370 !important; 
}`, "",{"version":3,"sources":["webpack://./src/components/Buyer/index.css"],"names":[],"mappings":";AACA;IACI,mBAAmB;AACvB;;AAEA;IACI,kCAAkC;IAClC,cAAc;AAClB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,gCAAgC;IAChC,4BAA4B;IAC5B,iBAAiB;IACjB,oCAAoC;IACpC,iCAAiC;AACrC;;AAEA;IACI,mDAAmD;IACnD,4CAA4C;IAC5C;mCAC+B;AACnC;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,4CAA4C;IAC5C,4BAA4B;IAC5B,2BAA2B;AAC/B","sourcesContent":["\n.modal-details-row{\n    margin-left: 0.5rem;\n}\n\n.header-schema{\n    font-family: 'Poppins', sans-serif;\n    color: #FFFFFF;\n}\n\n.main2Div{\n    background-color: #030B15;\n}\n\n.table-schema{\n    /* color: #FFFFFF !important;  */\n    font-size: 1.1rem !important;\n    font-weight: 3500;\n    --bs-table-color: #FFFFFF !important;\n    --bs-table-bg: #030B15 !important;\n}\n\n.table-border-bottom{\n    border-bottom: 2px solid rgba(255, 252, 252, 0.096);\n    font-family: 'Outfit', sans-serif !important;\n    /* font-size: 1rem !important;\n    font-weight: 400 !important;  */\n}\n\n.modal-header-schema{\n    background: #0B1D33 !important;\n}\n\n.modal-schema{\n    font-family: 'Outfit', sans-serif !important;\n    font-size: 1.1rem !important;\n    font-weight: 370 !important; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
