// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.addressClass{
    color: aliceblue;
    font-family: 'Outfit', sans-serif;
    font-size: 1rem !important;
    font-weight: 400 !important; 
     /* background: white; */
     /* padding: 0.3rem; */
     /* border-radius: 5%;    */
}

.navbar-header-scheme{
    font-family: 'Outfit', sans-serif;
    font-size: 1.2rem !important;
    font-weight: 600 !important;
}

.balanceClass{
    color: aliceblue;
    margin-left: 3rem;
    font-family: 'Outfit', sans-serif;
    font-size: 1rem !important;
    font-weight: 400 !important;   
}

.pointerCursor:hover{
    cursor: pointer;
}

.myCOntractClass{
    margin-left: 3rem;
}

.navMargin{
    margin-left: 1rem;
}

.btn-schema{
    background: #00C4F4 !important;
}

.navbar-schema{
    background: #030B15;
    border-bottom: 3px solid rgb(255 255 255 / 10%);
}`, "",{"version":3,"sources":["webpack://./src/components/Navbar/index.css"],"names":[],"mappings":";AACA;IACI,gBAAgB;IAChB,iCAAiC;IACjC,0BAA0B;IAC1B,2BAA2B;KAC1B,uBAAuB;KACvB,qBAAqB;KACrB,0BAA0B;AAC/B;;AAEA;IACI,iCAAiC;IACjC,4BAA4B;IAC5B,2BAA2B;AAC/B;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,iCAAiC;IACjC,0BAA0B;IAC1B,2BAA2B;AAC/B;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,mBAAmB;IACnB,+CAA+C;AACnD","sourcesContent":["\n.addressClass{\n    color: aliceblue;\n    font-family: 'Outfit', sans-serif;\n    font-size: 1rem !important;\n    font-weight: 400 !important; \n     /* background: white; */\n     /* padding: 0.3rem; */\n     /* border-radius: 5%;    */\n}\n\n.navbar-header-scheme{\n    font-family: 'Outfit', sans-serif;\n    font-size: 1.2rem !important;\n    font-weight: 600 !important;\n}\n\n.balanceClass{\n    color: aliceblue;\n    margin-left: 3rem;\n    font-family: 'Outfit', sans-serif;\n    font-size: 1rem !important;\n    font-weight: 400 !important;   \n}\n\n.pointerCursor:hover{\n    cursor: pointer;\n}\n\n.myCOntractClass{\n    margin-left: 3rem;\n}\n\n.navMargin{\n    margin-left: 1rem;\n}\n\n.btn-schema{\n    background: #00C4F4 !important;\n}\n\n.navbar-schema{\n    background: #030B15;\n    border-bottom: 3px solid rgb(255 255 255 / 10%);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
